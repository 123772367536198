const initialState = {
  profileList: {},
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_PROFILE_LIST':
      return Object.assign({}, state, {
        profileList: action.data.data,
      });
    default:
      return state;
  }
};

export default profile;
