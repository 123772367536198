export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const toggleSidebar = () => (dispatch, getState) => {
  const currentOfferState = getState().sideBar;
  dispatch({
    type: TOGGLE_SIDEBAR,
    payload: !currentOfferState,
  });
};

export const closeSidebar = () => ({
  type: TOGGLE_SIDEBAR,
  payload: false,
});
