import {
  UPDATE_ANNOUNCEMENT,
  BASE_ANNOUNCEMENT,
} from '../actions/create-announcement';

export default function(currentState = BASE_ANNOUNCEMENT, { type, payload }) {
  switch (type) {
    case UPDATE_ANNOUNCEMENT: {
      return { ...currentState, ...payload };
    }
    default:
      return currentState;
  }
}
