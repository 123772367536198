import styled from 'styled-components';

export const BrokenLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  padding: 5rem;
`;

export const BrokenLinkText = styled.p`
  margin-bottom: 5rem;
`;

export const BrokenReachLink = styled.img`
  cursor: pointer;
  margin-top: auto;
`;

export const Button = styled.button`
  width: 200px;
  height: 48px;
  border-radius: 59px;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  background-color: #333333;
  @media (max-width: 768px) {
    width: 87%;
    height: 48px;
  }
`;
