const initialState = {
  influencersList: {},
  influencersPackagesList: {},
};

const influencers = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_INFLUENCERS_LIST':
      return { ...state, influencersList: action.data };
    case 'LOAD_INFLUENCERS_PACKAGES_LIST':
      return Object.assign({}, state, {
        influencersPackagesList: action.data.data,
      });
    default:
      return state;
  }
};

export default influencers;
