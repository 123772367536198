import {
  BrokenLinkText,
  BrokenLinkWrapper,
  BrokenReachLink,
  Button,
} from './BrokenLink.styled';

import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

export default function BrokenLink() {
  const { push } = useRouter();
  const { t } = useTranslation('common');

  const navigateToLogin = () => push('/home');

  return (
    <BrokenLinkWrapper>
      <img
        src="/static/assets/images/vectors/error.svg"
        width={280}
        height={280}
      />
      <BrokenLinkText>{t('status.broken-link')}</BrokenLinkText>
      <Button onClick={navigateToLogin}>{t('sidebar.home')}</Button>
      <BrokenReachLink
        onClick={navigateToLogin}
        width={110}
        src="/static/assets/images/brand-logo.svg"
      />
    </BrokenLinkWrapper>
  );
}
