const initialState = {
  companyDataList: {},
};

const companyData = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_COMPANY_DATA_LIST':
      return Object.assign({}, state, {
        companyDataList: action.data.adRequest,
      });
    default:
      return state;
  }
};

export default companyData;
