import Axios from 'axios';
import Cookies from 'js-cookie';
import { UserWithStory } from '../../types/APIs/Login.js';
import { getUrl } from '../../utils/api.js';
import { clearSession } from '../../utils';

export const load_profile = (post: UserWithStory) => {
  return {
    type: 'LOAD_PROFILE_LIST',
    data: post,
  };
};

export const loadProfile = (currentAPI: string) => {
  return function(dispatch: any) {
    return getUrl(`${currentAPI}/profile`, 'reach_token')
      .then((data: any) => {
        if (data?.message === 'Not Found') {
          throw new Error('No such user found!!');
        } else {
          Cookies.set('currentID', data.data.id, { expires: 365 });
          dispatch(load_profile(data));
        }
      })
      .catch(err => {
        if (!Axios.isCancel(err)) clearSession();
      });
  };
};
