import { Announcement } from './../../types/APIs/Announcements';
import { APIResponse, Meta } from './../../types/APIs/ApiResponse';
import { getUrl } from '../../utils/api.js';

export const LOAD_ANNOUNCEMENT_LIST = 'LOAD_ANNOUNCEMENT_LIST';
export const BASE_LIST_ANNOUNCEMENT = {
  data: [],
  meta: {},
  isLoading: false,
};

export const loadAnnouncement = (currentAPI: string) => (dispatch: any) => {
  dispatch({
    type: LOAD_ANNOUNCEMENT_LIST,
    payload: {
      isLoading: true,
      meta: {},
      data: [],
    },
  });

  getUrl(`${currentAPI}/announcements`, 'reach_token').then(
    (data: APIResponse<Announcement, Meta>) => {
      if (data?.message === 'Not Found') {
        throw new Error('No such user found!!');
      } else {
        dispatch({
          type: LOAD_ANNOUNCEMENT_LIST,
          payload: {
            data: data.data,
            meta: data.meta,
            isLoading: false,
          },
        });
      }
    }
  );
};
