export enum cookiesActions {
  GET = 'get',
  REMOVE = 'remove',
  SET = 'set',
}
export type TObject = { [key: string]: any };
export interface ILoginFormValues {
  username: string;
  password: string;
}
