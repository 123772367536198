import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize('UA-121781799-3');
};

export const logPageView = (pagePath: string): void => {
  ReactGA.set({ page: pagePath });
  ReactGA.pageview(pagePath);
};

export const logEvent = (category = '', action = ''): void => {
  if (category && action) {
    ReactGA.event({ category, action });
  }
};
