import { cookiesActions, TObject } from '../types';
import { logout } from './auth';
import Cookies from 'js-cookie';

export const isUrl = (url: string) => {
  const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  let exp = new RegExp(expression);
  return exp.test(url.toLowerCase());
};

export const getFormData = (object: TObject) =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());

export const validateResponse = (response: Response) => {
  if (response.status === 500 || response.status === 401) return logout();
};

export const clearSession = () => {
  window.localStorage.removeItem('reachAccounts');
  handleCookie('token', cookiesActions.REMOVE);
  handleCookie('reach_token', cookiesActions.REMOVE);
  handleCookie('currentID', cookiesActions.REMOVE);
  window.localStorage.removeItem('login_time');
};

export const requestHeaders = () => ({
  'Content-Type': 'multipart/form-data',
  'Content-Language': handleCookie('next-i18next') || 'en',
});

export const handleCookie = (
  cookieName: string,
  action = cookiesActions.GET,
  value = '',
  options = {}
) => {
  try {
    switch (action) {
      case cookiesActions.GET:
        const cookie = Cookies.get(cookieName);
        if (cookie === undefined) return null;
        return cookie;

      case cookiesActions.REMOVE:
        Cookies.remove(cookieName);
        return true;

      case cookiesActions.SET:
        Cookies.set(cookieName, value, options);
        return true;

      default:
        return null;
    }
  } catch (error) {
    alert(`Error Accessing cookie "${cookieName}" with action "${action}"`);
    return null;
  }
};

export function validateEmail(email: string) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

export const isNumberNaN = (number: unknown) => isNaN(Number(number));
