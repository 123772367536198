import {
  GET_COMPANY_DATA,
  GET_COMPANY_TYPES,
  GET_AGE_GROUPS,
  SET_COMPANY_DATA_SUBMITTING,
  SET_AD_REQUEST,
  SET_COMPANY_DATA_LOADING,
  GET_INFLUENCERS_PACKAGES,
  COMPLETE_STEP_2,
  CLEAR_ALL,
} from '../actions/connect-influencers';

const CONNECT_INFLUENCERS_FORM = {
  companyTypes: [],
  ageGroups: [],
  steps: {
    companyData: {
      selectedData: {},
      adRequest: {},
      completed: false,
      isLoading: true,
      submitting: false,
    },
    targetAudience: {
      packages: [],
      selectedData: {},
      isLoading: false,
      completed: false,
    },
    influencerScript: {
      completed: false,
    },
    payment: {
      completed: false,
    },
  },
  completed: false,
};

export default function(
  currentState = CONNECT_INFLUENCERS_FORM,
  { type, payload }
) {
  switch (type) {
    case CLEAR_ALL: {
      return {
        ...currentState,
        steps: {
          ...CONNECT_INFLUENCERS_FORM.steps,
        },
      };
    }
    case GET_COMPANY_TYPES: {
      return {
        ...currentState,
        companyTypes: [...payload],
      };
    }
    case GET_AGE_GROUPS: {
      return {
        ...currentState,
        ageGroups: [...payload],
      };
    }
    case SET_COMPANY_DATA_LOADING: {
      return {
        ...currentState,
        steps: {
          ...currentState.steps,
          companyData: {
            ...currentState.steps.companyData,
            isLoading: true,
          },
        },
      };
    }
    case GET_COMPANY_DATA: {
      return {
        ...currentState,
        steps: {
          ...currentState.steps,
          companyData: {
            ...currentState.steps.companyData,
            isLoading: false,
            adRequest: {
              ...payload,
            },
          },
        },
      };
    }
    case SET_COMPANY_DATA_SUBMITTING: {
      if (payload) {
        return {
          ...currentState,
          steps: {
            ...currentState.steps,
            companyData: {
              ...currentState.steps.companyData,
              submitting: true,
            },
          },
        };
      } else {
        return {
          ...currentState,
          steps: {
            ...currentState.steps,
            companyData: {
              ...currentState.steps.companyData,
              submitting: false,
              isLoading: false,
            },
          },
        };
      }
    }
    case SET_AD_REQUEST: {
      return {
        ...currentState,
        steps: {
          ...currentState.steps,
          companyData: {
            ...currentState.steps.companyData,
            submitting: false,
            completed: true,
            adRequest: {
              ...payload,
            },
          },
        },
      };
    }
    case GET_INFLUENCERS_PACKAGES: {
      if (payload === 'LOADING') {
        return {
          ...currentState,
          steps: {
            ...currentState.steps,
            targetAudience: {
              ...currentState.steps.targetAudience,
              isLoading: true,
            },
          },
        };
      } else {
        const filteredPackages = payload.filter(
          el => el.influencers.length > 0
        );
        return {
          ...currentState,
          steps: {
            ...currentState.steps,
            targetAudience: {
              ...currentState.steps.targetAudience,
              isLoading: false,
              packages: [...filteredPackages],
            },
          },
        };
      }
    }
    case COMPLETE_STEP_2: {
      return {
        ...currentState,
        steps: {
          ...currentState.steps,
          targetAudience: {
            ...currentState.steps.targetAudience,
            completed: true,
          },
        },
      };
    }

    default:
      return currentState;
  }
}
