const initialState = {
  filteredInfluencers: [],
  showFilterPopup: false,
  loading: false,
  applyFilter: false,
};

const filteredInfluencer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH_FILTER':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_FILTER_INFLUENCERS':
      return {
        ...state,
        filteredInfluencers: action.payload,
        loading: false,
        applyFilter: true,
      };
    case 'UNSET_FILTER_INFLUENCERS':
      return {
        ...state,
        filteredInfluencers: action.data,
        loading: false,
        applyFilter: false,
      };
    case 'SET_FILTER_POPUP':
      return {
        ...state,
        showFilterPopup: !state.showFilterPopup,
      };
    default:
      return state;
  }
};

export default filteredInfluencer;
