export const GET_COMPANY_DATA = 'GET_COMPANY_DATA';
export const GET_COMPANY_TYPES = 'GET_COMPANY_TYPES';
export const SET_COMPANY_DATA_SUBMITTING = 'SET_COMPANY_DATA_SUBMITTING';
export const SET_AD_REQUEST = 'SET_AD_REQUEST';
export const SET_COMPANY_DATA_LOADING = 'SET_COMPANY_DATA_LOADING';
export const GET_INFLUENCERS_PACKAGES = 'GET_INFLUENCERS_PACKAGES';
export const GET_AGE_GROUPS = 'GET_AGE_GROUPS';
export const COMPLETE_STEP_2 = 'COMPLETE_STEP_2';
export const CLEAR_ALL = 'CLEAR_ALL';
import Cookies from 'js-cookie';

import { getUrl, influencerPost } from '../../utils/api.js';
import { v4 as uuidv4 } from 'uuid';
import { clearSession } from '../../utils';

export const resetAll = () => dispatch =>
  dispatch({
    type: CLEAR_ALL,
  });

export const fetchCompanyData = currentAPI => {
  return dispatch => {
    dispatch({
      type: SET_COMPANY_DATA_LOADING,
    });
    getUrl(`${currentAPI}/companies/ad-requests/last`).then(res => {
      dispatch({
        type: GET_COMPANY_DATA,
        payload: res.adRequest,
      });
    });
  };
};
export const fetchCompanyTypes = currentAPI => {
  return dispatch => {
    dispatch({
      type: GET_COMPANY_TYPES,
      payload: 'LOADING',
    });
    getUrl(`${currentAPI}/companies/ad-requests/company_types`)
      .then(res => {
        dispatch({
          type: GET_COMPANY_TYPES,
          payload: res.data,
        });
      })
      .catch(err => console.log(err));
  };
};
export const fetchAgeGroups = currentAPI => {
  return dispatch => {
    dispatch({
      type: GET_COMPANY_TYPES,
      payload: 'LOADING',
    });
    getUrl(`${currentAPI}/companies/ad-requests/age_groups`)
      .then(res => {
        dispatch({
          type: GET_AGE_GROUPS,
          payload: res.data,
        });
      })
      .catch(err => {
        clearSession();
        window.location.href = '/login';
      });
  };
};
export const postAdRequest = (currentAPI, data, nextStep) => {
  return dispatch => {
    dispatch({
      type: SET_COMPANY_DATA_SUBMITTING,
      payload: true,
    });

    influencerPost(`${currentAPI}/companies/ad-requests/create`, data)
      .then(res => {
        dispatch({
          type: SET_AD_REQUEST,
          payload: res.data.adRequest,
        });
        nextStep();
      })
      .catch(err => {
        dispatch({
          type: SET_COMPANY_DATA_SUBMITTING,
          payload: false,
        });
      });
  };
};

export const loadCompanyData = currentAPI => {
  return function(dispatch) {
    return getUrl(`${currentAPI}/companies/ad-requests/last`).then(data => {
      dispatch(load_company_data(data));
    });
  };
};

export const completeStep2 = () => dispatch =>
  dispatch({
    type: COMPLETE_STEP_2,
  });

export const loadInfluencersPackages = (currentAPI, data) => {
  return function(dispatch) {
    dispatch({
      type: GET_INFLUENCERS_PACKAGES,
      payload: 'LOADING',
    });
    return influencerPost(
      `${currentAPI}/companies/packages/dynamic`,
      JSON.stringify(data),
      true
    ).then(({ data }) => {
      if (data?.message === 'Not Found') {
        throw new Error('No such user found!!');
      } else {
        var collectData = data;
        for (var i = 0; i < collectData.data.length; i++) {
          for (var k = 0; k < collectData.data[i].influencers.length; k++) {
            collectData.data[i].influencers[k].uniqe_key = uuidv4();
          }
        }
        dispatch({
          type: GET_INFLUENCERS_PACKAGES,
          payload: collectData.data,
        });
      }
    });
  };
};

// export const setAnnouncementStatus = status => (dispatch, getState) => {
//   const currentAnnouncementState = getState().createAnnouncementForm;
//   const changedAnnouncement = {
//     ...currentAnnouncementState,
//     status: status,
//   };
//   dispatch({
//     type: UPDATE_ANNOUNCEMENT,
//     payload: changedAnnouncement,
//   });
// };
// export const setAnnouncementErrors = errors => (dispatch, getState) => {
//   const currentAnnouncementState = getState().createAnnouncementForm;
//   const changedAnnouncement = {
//     ...currentAnnouncementState,
//     errors: errors,
//   };
//   dispatch({
//     type: UPDATE_ANNOUNCEMENT,
//     payload: changedAnnouncement,
//   });
// };

// export const updateAnnouncement = input => (dispatch, getState) => {
//   const currentAnnouncementState = getState().createAnnouncementForm;
//   const changedAnnouncement = {
//     ...currentAnnouncementState,
//     inputs: {
//       ...currentAnnouncementState.inputs,
//       ...input,
//     },
//   };
//   dispatch({
//     type: UPDATE_ANNOUNCEMENT,
//     payload: changedAnnouncement,
//   });
// };

// export const clearAnnouncement = () => (dispatch, getState) => {
//   dispatch({
//     type: UPDATE_ANNOUNCEMENT,
//     payload: BASE_ANNOUNCEMENT,
//   });
// };
