import {
  ADD_NEW_OFFER,
  UPDATE_OFFER,
  BASE_OFFER,
} from '../actions/create-offer';

const DEFAULT_OFFER_STATE = {
  'offer-1': { ...BASE_OFFER },
};

export default function(currentState = DEFAULT_OFFER_STATE, { type, payload }) {
  switch (type) {
    case ADD_NEW_OFFER: {
      return { ...currentState, ...payload };
    }
    case UPDATE_OFFER: {
      return { ...currentState, ...payload };
    }
    default:
      return currentState;
  }
}
