const initialState = {
  termsConditionsList: {},
};

const termsConditions = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_TERMS_CONDITIONS_LIST':
      return Object.assign({}, state, {
        termsConditionsList: action.data.data,
      });
    default:
      return state;
  }
};

export default termsConditions;
