const initialState = {
  platformsList: {},
};

const socialPlatforms = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_SOCIAL_PLATFORMS_LIST':
      return Object.assign({}, state, {
        platformsList: action?.data?.data ?? [],
      });
    default:
      return state;
  }
};

export default socialPlatforms;
