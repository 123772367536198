import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/nextjs';
import Error404Page from '../../pages/404';

const ErrorFallback = () => {
  return <Error404Page />;
};

const myErrorHandler = (error, errorInfo) => {
  Sentry.captureException(error, { extra: errorInfo });
};

const ErrorBoundary = ({ children }) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={myErrorHandler}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
