import { TOGGLE_SIDEBAR } from '../actions/sidebar';

const DEFAULT_SIDEBAR = false;

export default function(currentState = DEFAULT_SIDEBAR, { type, payload }) {
  switch (type) {
    case TOGGLE_SIDEBAR: {
      return payload;
    }
    default:
      return currentState;
  }
}
