import { combineReducers } from 'redux';
import { routerReducer } from 'connected-next-router';
import influencers from './influencers';
import profile from './profile';
import socialPlatforms from './social-platforms';
import companyData from './company-data';
import termsConditions from './terms-conditions';
import createOfferForm from './createOfferForm';
import connectInfluencersForm from './connectInfluencersForm';
import createAnnouncementForm from './createAnnouncementForm';
import announcement from './announcement';
import sideBarReducer from './sideBarReducer';
import filteredInfluencer from './filteredInfluencers';

const rootReducer = preloadedState =>
  combineReducers({
    router: routerReducer,
    sideBar: sideBarReducer,
    createOfferForm,
    connectInfluencersForm,
    createAnnouncementForm,
    influencers,
    profile,
    socialPlatforms,
    companyData,
    termsConditions,
    announcement,
    filteredInfluencer,
  });

export default rootReducer;
