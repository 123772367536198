export const UPDATE_OFFER = 'UPDATE_OFFER';
export const ADD_NEW_OFFER = 'ADD_NEW_OFFER';
export const BASE_OFFER = {
  status: '',
  inputs: {
    cta: '',
    cta_url: '',
    cta_text_color: '',
    cta_background_color: '',
    start_at: '',
    due_date: '',
  },
};

export const setOfferStatus = (offerKey, status) => (dispatch, getState) => {
  const currentOfferState = getState().createOfferForm[offerKey];
  const changedOffer = {
    [offerKey]: {
      ...currentOfferState,
      status: status,
    },
  };
  dispatch({
    type: UPDATE_OFFER,
    payload: changedOffer,
  });
};

export const saveChanges = (input, offerKey) => (dispatch, getState) => {
  const currentOfferState = getState().createOfferForm[offerKey];
  const changedOffer = {
    [offerKey]: {
      ...currentOfferState,
      inputs: {
        ...currentOfferState.inputs,
        ...input,
      },
    },
  };
  dispatch({
    type: UPDATE_OFFER,
    payload: changedOffer,
  });
};

export const addOfferAction = offerKey => dispatch => {
  const newOffer = {
    ['offer-' + offerKey]: {
      ...BASE_OFFER,
    },
  };
  dispatch({
    type: ADD_NEW_OFFER,
    payload: newOffer,
  });
};
