export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT';
export const BASE_ANNOUNCEMENT = {
  status: '',
  errors: {},
  inputs: {
    media: '',
    title: '',
    desc: '',
    cta: '',
    cta_text_color: '#ffffff',
    cta_background_color: '#0177C0',
    cta_link: '',
    cta_whatsapp: '',
  },
};

export const setAnnouncementStatus = status => (dispatch, getState) => {
  const currentAnnouncementState = getState().createAnnouncementForm;
  const changedAnnouncement = {
    ...currentAnnouncementState,
    status: status,
  };
  dispatch({
    type: UPDATE_ANNOUNCEMENT,
    payload: changedAnnouncement,
  });
};
export const setAnnouncementErrors = errors => (dispatch, getState) => {
  const currentAnnouncementState = getState().createAnnouncementForm;
  const changedAnnouncement = {
    ...currentAnnouncementState,
    errors: errors,
  };
  dispatch({
    type: UPDATE_ANNOUNCEMENT,
    payload: changedAnnouncement,
  });
};

export const updateAnnouncement = input => (dispatch, getState) => {
  const currentAnnouncementState = getState().createAnnouncementForm;
  const changedAnnouncement = {
    ...currentAnnouncementState,
    inputs: {
      ...currentAnnouncementState.inputs,
      ...input,
    },
  };
  dispatch({
    type: UPDATE_ANNOUNCEMENT,
    payload: changedAnnouncement,
  });
};

export const clearAnnouncement = () => (dispatch, getState) => {
  dispatch({
    type: UPDATE_ANNOUNCEMENT,
    payload: BASE_ANNOUNCEMENT,
  });
};
