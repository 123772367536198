import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {
  createRouterMiddleware,
  initialRouterState,
} from 'connected-next-router';

import rootReducer from './reducers';

const composeEnhancers =
  (typeof window != 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const middleware = [createRouterMiddleware(), thunk];

// if (process.env.NODE_ENV === `development`) {
//   const { logger } = require(`redux-logger`);
//   middleware.push(logger);
// }

let store;

export function initializeStore(preloadedState = {}, options) {
  if (options.asPath) {
    preloadedState.router = initialRouterState(options.asPath);
  }

  store = createStore(
    rootReducer(preloadedState),
    preloadedState,
    composeEnhancers(applyMiddleware(...middleware))
  );
  return store;
}
