import {
  LOAD_ANNOUNCEMENT_LIST,
  BASE_LIST_ANNOUNCEMENT,
} from '../actions/announcement';

export default function(
  currentState = BASE_LIST_ANNOUNCEMENT,
  { type, payload }
) {
  switch (type) {
    case LOAD_ANNOUNCEMENT_LIST: {
      return { ...currentState, ...payload };
    }
    default:
      return currentState;
  }
}
